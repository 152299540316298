<template>
    <!-- 
       HearUS. world
    -->
    <div class="mint_container">       

    
        <div class="content-tab  tab-general">
            {{ consolelog }}
        </div>

        <!-- Wallet panel -->
        <div v-if="idWalletPanel" class="tab-general" style="min-height:50px;">
            <div style=" float:left; min-width:400px;">
                <img src="../../src/assets/images/HearUS_logo.svg" style="width:100px;"/>
            </div>
            <div  v-if="isMetamask">
                <div style="float:left;">
                    <img src="../../src/assets/images/icon_stat.png" @click="closeAllTab(),isStatTab=true" />
                    <img src="../../src/assets/images/icon_vote.png" @click="closeAllTab(),isMyVote=true" />
                </div>
                <div style="float:right;">
                    <!-- Right : connect wallet and Balance Display -->
                    <div id="wallet_right" style="padding-left:5px; float:right;">
                        <div style="float:left; text-align: center; line-height: 40px; height: 40px; width:95px;border-radius:5px 0 0 5px; background:black; color:white;">
                            {{walletBalance}}
                        </div>
                        <el-button v-if="!isConnect" @click="dialogVisible = true" style="float:left; width:120px;border-radius:0 5px 5px 0;border:1px solid black; "> 
                            Connect wallet
                        </el-button>
                        <el-button v-if="isConnect" @click="dialogVisible = true" style="float:left; width:120px;border-radius:0 5px 5px 0; border:1px solid black;" > 
                        {{walletShortAddress}}
                        </el-button>
                    </div>

                    <!-- Left : install metamask and select network -->
                    <div id="wallet_left" style="padding-right:3px; float:right;">
                        <!-- Dialog : Connect Metemask -->
                        <el-dialog width="30%" :visible.sync="dialogVisible" style="border:1px solid black;">
                            <el-button width="90%" @click="connectWallet()">Metamask </el-button>
                        </el-dialog>
                        <el-button v-if="!isMetamask" style="width:135px; float:right;border-radius:5px; border:1px solid black;"
                                @click="goToURL('https://metamask.io/')" > Install Metamask
                        </el-button>
                        <el-button v-if="!isChain && isMetamask" style="width:95px; float:right; border-radius: 5px;border:1px solid black;" 
                                    @click="switchNetwork()" > ⚠ Switch 
                        </el-button>
                        <el-button class="btn-network" v-if="isChain" style="width:95px; float:right;border-radius: 5px;border:1px solid black;" > {{ chainName}}
                        </el-button>
                    </div>

                </div>
            </div>
            <div style="clear:both;"></div>
        </div>

       

        <!-- Start Screen : Install Metamask -->
        <div class="tab-start-empty tab-general" v-if="!isMetamask" >


            <div style="width:350px;  margin:0 auto; font-size:1.2em;  ">
                <img src="../../src/assets/images/MYGE15.png" style="margin:0 auto; height:350px;"/>

                <p style="padding:0;"> Get a  <span style="font-weight:600; color:#560bfc">Free #MYGE15 NFT</span>  just by submitting two survey questions </p>

                <el-button  
                  style="background-color: #00c617;width:200px; color:white; border-radius: 50px; border:none; font-size:1em; margin-bottom:30px; "  
                  @click="goToURL('https://metamask.io/')">
                    Install Metamask 
                </el-button> 
            </div>
            
        </div>


        <!-- Start Screen : Switch Network -->
        <div class="tab-start-empty tab-general" v-if="isMetamask && !isChain" >
            <div style="width:310px;  height:230px; margin:0 auto; font-size:1.2em; margin-top:90px;  ">
                <div>
                    Amplifying your voice <br>
                    <p>Your voice will be anonymous in our surveys. However, to help us amplify it even greater feel free to share to twitter! </p>
                </div>
                <br>
                <img src="../../src/assets/images/icon_metamask_Polygon.png" /><br>
                
                <el-button  
                  style="background-color: #00c617;width:200px; color:white; border-radius: 50px; border:none; font-size:1em; margin:0 auto; margin-top:20px;"  
                  @click="switchNetwork()">
                  ⚠ Switch network
                </el-button> 
            </div>
        </div>

        <!-- Statistic panel -->
        <div v-if="isStatTab  && isMetamask && isChain" class="content-tab  tab-general">
            <div>
                <div @click=" isNFTTab=false, isStatTab = true"  style="float:left; width: 50px; padding:5px 15px; background:black; color: white; cursor: pointer;">
                    Survey
                </div> 
                <div @click=" isNFTTab=true, isStatTab = false" style="float:left; min-width:20px; padding:5px 15px; color:white; background:#560bfc; cursor: pointer;">
                    NFT
                </div> 
                <div style="float:left; min-width: 100px; padding:5px 15px;" > 
                    <span style="font-size:0.75em;">Latest Block </span>{{latestBlock}}
                </div>
                <div style="float:right; min-width: 100px; padding:5px 15px; color: #560bfc;" > {{totalSubmission}} submissions </div>
                <p style="clear:both;min-width:60%; border:1px solid black; padding:15px">
                    Who should be Malaysia next Prime Minister?
                </p>
            </div>


            <div style="border:1px solid black; margin-top:20px;padding:10px;">
                Get a  <span style="font-weight:600; color:#560bfc">Free #MYGE15 NFT</span> 
            </div>
            <div style="background: #eee;padding:10px; margin-bottom:24px;">
                ⬇ 🎁 Select from below options, gas Fee is fully sponsored.
            </div>

            <div>
                <div style="float:left; padding:5px 15px;">
                    <span style="color:red;">LIVE</span> Poll
                </div>
            </div>

            <ul style="list-style: none; padding:0px;  clear:both;">
                <li style="width:100%;min-height:80px;border:1px solid black; margin-bottom:20px; border-radius: 5px;" v-for="(item ,index) in calon"  :key="index" @click="viewCalon(index)" >
                    <div style="float:left;">
                        <img :src= item.image style="width: 80px; padding:0 2px;"/>
                    </div>
                    <div style="float:left;">
                        <p style="padding:0;margin:0; width:320px;"><span style="font-size: 2em;">{{ item.vote }}</span> <br>
                            {{ item.name }}<br>
                            <el-progress :text-inside="true" :stroke-width="10" :show-text="false" :percentage="item.percentage" ></el-progress>
                        </p>
                    </div>
                    
                </li>
            </ul>

            <div style=" min-height:150px;padding:20px; cursor: pointer;" @click="goToURL( contract.polygonScan )">
                <img src="https://polygonscan.com/images/logo.svg?v=0.0.3" style="width:150px;"/> : 0x2EDDaBCBa7cb361b4bA5501b46Ef9A12E814bEA5
            </div>

        </div>

        <!-- NFT panel -->
        <div v-if="isNFTTab  && isMetamask && isChain" class="content-tab  tab-general">
            <div>
                <div @click=" isNFTTab=false, isStatTab = true"  style="float:left; width: 50px; padding:5px 15px; background:black; color: white; cursor: pointer;">
                    Survey
                </div> 
                <div @click=" isNFTTab=true, isStatTab = false"  style="float:left; min-width:20px; padding:5px 15px; color:white; background:#560bfc; cursor: pointer;">
                    NFT
                </div> 
                <div style="float:left; min-width: 100px; padding:5px 15px;" > 
                    <span style="font-size:0.75em;">Latest Block </span>{{latestBlock}}
                </div>
                <div style="float:right; min-width: 100px; padding:5px 15px; color: #560bfc;" > {{totalSubmission}} submissions </div>
                <p style="clear:both;min-width:60%; border:1px solid black; padding:15px">
                    Who should be Malaysia next Prime Minister?
                </p>
            </div>

            Generative of 25 latest minted NFT

            <ul style="list-style: none; padding:0px;margin:0px;  ">
                <li style=" float:left; width:95px;height:95px" v-for="(item ,index) in NFTs"  :key="index" @click="viewCalon(index)" >
                    <img :src= item.image style="width: 95px; height:95px;"/>     
                </li>
                <div style=" clear:both;"></div>
            </ul>

            <div style=" clear:both; :150px;padding:20px; cursor: pointer;" @click="goToURL( contract.polygonScan )">
                <img src="https://polygonscan.com/images/logo.svg?v=0.0.3" style="width:150px;"/> : 0x2EDDaBCBa7cb361b4bA5501b46Ef9A12E814bEA5
            </div>

        </div>




        <!-- Calon panel -->
        <div v-if="isCalonTab" class="calon-tab  tab-general" style=" padding-bottom:60px;">
            <div>
                <div style="float:left; width: 80px; padding:5px 15px; background:black; color: white;">
                    Survey Q1
                </div>
                <p style="clear:both;min-width:60%; border:1px solid black; padding:15px">
                    Who should be our next Prime Minister?
                </p>
            </div>
            <div style="border:1px solid black; border-radius:10px;">
                <div style="float:left;height:150px; width:150px;">
                    <img :src= calon[viewCalonIndex].image style="width: 130px; padding:10px; text-align: center;"/>
                </div>
                <div style="float:right;line-height:120px;width:50%;text-align: center; border-left:1px dotted #111;">
                    <span style="font-size: 5em; text-align: right;">{{ calon[viewCalonIndex].vote }}</span>
                </div>

                <div  style="clear:both; width:90%; margin:0 auto; padding:5px; height:140px;"> 
                    <span style="font-weight: 600; font-size:1.5em;">{{ calon[viewCalonIndex].name }} </span><br>
                    {{ calon[viewCalonIndex].party }} ➡ {{ calon[viewCalonIndex].seat }}<br><br>
                    <div style="vertical-align: middle;line-height:30px; width:46%; padding-right:10px; float:left;" @click="goToURL(calon[viewCalonIndex].twitter)">
                        <img src="../../src/assets/images/icon_wiki.png" style="width:20px;vertical-align: middle;line-height:30px; "/> {{ calon[viewCalonIndex].twitter.substr(30, 25) }} 
                    </div>
                    <div style="vertical-align: middle;line-height:30px; width:46%; padding-right:10px; float:left;" @click="goToURL(calon[viewCalonIndex].wiki)">
                        <img src="../../src/assets/images/icon_twitter.png"  style="width:20px;vertical-align: middle;line-height:30px;" /> {{ calon[viewCalonIndex].wiki.substr(20, 25) }}
                    </div>
                </div>
            </div>

            <div style="padding:10px;">
                <el-button style="width:135px; border-radius: 8px;"  @click="closeAllTab(), isStatTab = true">
                    Back
                </el-button>
                <el-button style="width:135px; float:right; border-radius: 8px;background-color:#560bfc;color:white; "  @click="closeAllTab(), isHearUSTab = true">
                    Next 
                </el-button>
            </div>
        </div>


        <!-- HearUS panel -->
        <div v-if="isHearUSTab" class="calon-tab  tab-general" style=" padding-bottom:60px;">
            <div>
                <div style="float:left; width: 80px; padding:5px 15px; background:black; color: white;">
                    Survey Q2
                </div>
                <p style="clear:both;min-width:60%; border:1px solid black; padding:15px">
                    What is the one most important thing that the next government should do?
                </p>
            </div>
            <div v-if="myVote.wallet=='0x0000000000000000000000000000000000000000'" style=" border-radius:10px; height: 180px; border-radius: 10px; border: 1px solid black; padding:10px;">
                <el-form :model="form"  ref="nameForm" style="background-color:#CCC;padding:5px; border-radius: 10px;">
                    <el-form-item  prop="name"  style="width:95%; margin: 0 auto; ">
                        <el-input  type="textarea" v-model="form.HearUS" placeholder="Political stable ... " ></el-input>
                    </el-form-item>     
                    <p style="width:95%; margin: 0 auto; font-size:0.8em;">limit to 150 charectors only </p>
                </el-form>


                <div style="padding-top:50px;">
                    <!--
                    <el-button style="background-color:#560bfc;color:white; width:120px; float:right;  border-radius: 8px;"  @click="submitSurvey(calon[viewCalonIndex].ID), closeAllTab(), isVoteAndMintTab=true, isSendBlockchain=true">-->
                    <el-button style="background-color:#560bfc;color:white; width:120px; float:right;  border-radius: 8px;"  @click="submitSupport(0,calon[viewCalonIndex].ID), closeAllTab(), isVoteAndMintTab=true, isProcessCompile=true">
                        Submit
                    </el-button>
                    <el-button style=" border:none; width:120px; float:right; margin-right:10px; border-radius: 8px;"  @click="closeAllTab(), isCalonTab = true">
                        Back
                    </el-button>
                </div>
            </div>

            <p  style="width:100%; margin-top:50px;text-align: center;" v-if="myVote.wallet!='0x0000000000000000000000000000000000000000'"> This wallet address had submited the survey</p>

            <div v-if="myVote.wallet=='0x0000000000000000000000000000000000000000'" style="padding:20px; margin-top:70px; color:white; border-radius: 10px; background-color:#560bfc; height:130px;">
                
                <p style=""> Sponsor HearUS and you will receive a unique #MYGE15 NFT - "Big Head to Putrajaya"</p>
                <el-button  style="width:150px; float:right; border-radius: 8px;"  @click="submitSupport(100, calon[viewCalonIndex].ID), closeAllTab(), isVoteAndMintTab=true, isProcessCompile=true">
                    100 MATIC
                </el-button>
                
            </div>
        </div>

        <!-- Vote + Mint NFT Generate Metadata, Image, Store into IPFS and trigger Metamask for minting -->
        <div v-if="isVoteAndMintTab" class="calon-tab  tab-general" style=" padding-bottom:60px;">
            
            <div v-if="isProcessVoteComplete" style="border:1px solid black; border-radius:10px; height:50px; padding:90px 15px; vertical-align: middle; text-align: center;">
                Your voice will be anonymous in our surveys.<br>
                However, to help us amplify it even greater,<br>
                feel free to share in twitter.<br><br>
                <el-button style="width:135px; margin:0 auto; border-radius: 8px;"  @click="isVoteAndMintTab = false, isMyVote = true">
                    view 
                </el-button>
            </div>

            <div v-if="isProcessCompile" style="border:1px solid black; border-radius:10px; height:50px; padding:90px 15px; vertical-align: middle; text-align: center;">
                Compiling data ... <br><br>
                Thanks for supporting Web3 anonymous survey<br>
                You will receive your NFT shortly.
            </div>

            <div v-if="isSendBlockchain" style="border:1px solid black; border-radius:10px; height:50px; padding:90px 15px; vertical-align: middle; text-align: center;">
                 Minting... <br><br>on Polygon blockchain Network <br> 
            </div>

            <div v-if="isTransactionEnd" style="border:1px solid black; border-radius:10px; height:50px; padding:90px 15px; vertical-align: middle; text-align: center;">
                Submitted successful!<br>
                Follow out Twitter.<br>

                <el-button style="width:135px; margin:0 auto; border-radius: 8px;"  @click="goToURL('https://twitter.com/HearUS_world') ">
                     Follow
                </el-button>
            </div>

        </div>


        <!-- My vote panel -->
        <div v-if="isMyVote" class="work-tab  tab-general">
            <h2> Submission</h2>

            <div v-if="myVote.wallet=='0x0000000000000000000000000000000000000000'">
                <div style="float:left; width: 80px; padding:5px 15px; background:black; color: white;">
                    Survey
                </div>
                <p style="clear:both; margin:0; min-width:60%; border:1px solid black; padding:15px">
                    No submission.
                </p>
            </div>

            <div v-if="myVote.wallet!='0x0000000000000000000000000000000000000000'">
                <div style="float:left; width: 80px; padding:5px 15px; background:black; color: white;">
                    Survey Q1
                </div>
                <p style="clear:both; margin:0; min-width:60%; border:1px solid black; padding:15px">
                    Who should be our next Prime Minister?
                </p>
                <p style="margin:0;margin-bottom:20px; min-width:60%; border:1px solid black; background-color:#560bfc; color:white;padding:15px">
                    {{ myVote.calonName}}
                </p>
            </div>

            <div v-if="myVote.wallet!='0x0000000000000000000000000000000000000000'">
                <div style="float:left; width: 80px; padding:5px 15px; background:black; color: white;">
                    Survey Q2
                </div>
                <p style="clear:both;margin:0; min-width:60%; border:1px solid black; padding:15px">
                    What is the one most important thing that the next government should do?
                </p>
                <p style="margin:0; min-width:60%; border:1px solid black; background-color:#560bfc; color:white;padding:15px">
                    {{ myVote.HearUs}}
                </p>
            </div>

            <div v-if="myVote.support">
                <div style="font-size:2em; padding:10px; margin-top:30px;">NFT <span style="font-weight: 600;"> MYGE15 # {{myNFT.tokenID}} </span></div>
                <div style=" width:100%;  border-radius: 5px; padding:5px; border:1px solid grey;">
                    <el-image  style="width:100%; border-radius: 10px;" :src="myNFT.image" ></el-image>
                </div>
                <ul class="nft-field">
                    <li > 
                        <div class="nft-field-1">IPFS IMAGE</div> 
                        <div class="nft-field-2" @click="goToURL(myNFT.image)">{{myNFT.image}}</div>
                    </li>
                    <li > 
                        <div class="nft-field-1">Descriptions</div> 
                        <div class="nft-field-2">{{myNFT.description}}</div>
                    </li>
                    <li> 
                        <div class="nft-field-1">Publisher </div>
                        <div class="nft-field-2"> {{myNFT.external_url}} </div>
                    </li>
                    <li> 
                        <div class="nft-field-1">Art Name </div> 
                        <div class="nft-field-2">{{myNFT.name}}</div>
                    </li>
                    <li> 
                        <div class="nft-field-1">HearUS</div>
                        <div class="nft-field-2">{{myNFT.HearUS}}</div>
                    </li>
                    <li> Traits</li>
                    <li> 
                        <div class="nft-field-1">Art DNA</div> 
                        <div class="nft-field-2">{{myNFT.DNA}}</div>
                    </li>
                    <li>
                        <div class="nft-field-1">Candidate</div> 
                        <div class="nft-field-2">{{myNFT.Candidate}}</div>
                    </li>
                    <li> 
                        <div class="nft-field-1">Party</div>
                        <div class="nft-field-2">{{myNFT.Party}}</div>
                    </li>
                    <li> 
                        <div class="nft-field-1">Twitter</div>
                        <div class="nft-field-2">{{myNFT.Twitter}}</div>
                    </li>
                    <li> 
                        <div class="nft-field-1">Wikipedia</div>
                        <div class="nft-field-2">{{myNFT.Wikipedia}}</div>
                    </li>
                    <li> 
                        <div class="nft-field-1">Created</div>
                        <div class="nft-field-2">{{myNFT.Created}}</div>
                    </li>
                </ul>
            </div>




        </div>
       
    </div>
    
</template>

<script>
import abi_collectible from '../web3/abi_collectible';
import abi_poll from '../web3/abi_poll';


import getWeb3 from '../web3/web3';

/**
const target_chain = '0x13881';
const target_chainName = 'Mumbai';
const target_rpcUrls = 'https://matic-mumbai.chainstacklabs.com';
const target_name = 'Mumbai';
const target_symbol = 'MATIC';
const target_decimals = 18;
const target_blockExplorerUrls = 'https://mumbai.polygonscan.com';



const contract_collectibles = '0x0BA4759DbD1c2827c16411dA97bb85F6233d3aea';
const contract_poll = '0xa32ECC184D423011E4c2e4a38F23Eb854B7F1393';
const authKey = '0x000000000000000000000000697cb3a91d22f4cb39aeea7eb4a410fe090bbe06';

*/


const target_chain = '0x89';
const target_chainName = 'Polygon';
const target_rpcUrls = 'https://polygon-rpc.com';
const target_name = 'Polygon';
const target_symbol = 'MATIC';
const target_decimals = 18;
const target_blockExplorerUrls = 'https://polygonscan.com';



const contract_collectibles     = '0x365078d2aDBb7494101DF45ddFe1632889c2aC1F';
const contract_poll             = '0x2EDDaBCBa7cb361b4bA5501b46Ef9A12E814bEA5';
const authKey                   = '0x000000000000000000000000697cb3a91d22f4cb39aeea7eb4a410fe090bbe06';




// IPFS setting

// Import the NFTStorage class and File constructor from the 'nft.storage' package
import { NFTStorage, File } from 'nft.storage'

// The 'mime' npm package helps us set the correct file type on our File objects
import mime from 'mime'

// The 'fs' builtin module on Node.js provides access to the file system
import fs from 'fs'

// The 'path' module provides helpers for manipulating filesystem paths
import path from 'path'

// Paste your NFT.Storage API key into the quotes:
const NFT_STORAGE_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweEM4QjQ2NzQ3YTcxQzNDZjUzOTE3RTE3ZWVhQWRmMTVGMzU1RjExOWUiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTY0MjQ2NTUxNjY3MywibmFtZSI6Im1lb3cifQ.ecyM6rhdmtiZhgxLRZNLaOVRIcAFBFASY3OJTieRM9M'

// -- end IPFS

//const provider = window.ethereum


export default {
  name: 'App',
  data() {
    return {

        consolelog :'cibar',
        
        contract :{
            collectibles:null,
            poll:null,
            polygonScan: 'https://polygonscan.com/address/' + contract_poll,
        },

        calon: [{

            ID      : null,
            name    : null,
            image   : null,
            party   : null,
            seat    : null,
            twitter : null,
            wiki    : null,
            vote    : null,
            support : null,
            percentage :  0

        }],
        votedCalon : {
            wallet: '',
            id : null,
            name:'',
            image:'',
            party:'',
            seat:'',
            twitter:'',
            wiki:'',
            HearUS:''
        },


        viewCalonIndex : 0,
        voteOption: 0,
        form :{
            HearUS :''
        },

        accountAddress: null,
        isConnect: false,
        idWalletPanel: true,
        isMetamask: false,
        isChain : false,
        chainId : 0,
        chainName : 'Polygon',
        walletBalance : '',
        latestBlock : 0,
        topVote : 0,
        totalSubmission : 0,
        calonCount : 0,
        donation :0,
        NFTs :[],

        returnMetadata:'',
        imageInIPFS:'',
        
        isStatTab   : true,
        isCalonTab  : false,
        isHearUSTab : false,
        isMyVote    : false,
        isVoteAndMintTab : false,
        isNFTTab    : false,


        isProcessCompile : false,
        isSendBlockchain : false,
        isTransactionEnd : false,
        isProcessVoteComplete : false,

        dialogVisible: false,

        myNFT : {
            id : '',
            option : '',
            image : ''
        },

        

        myVote : {
            calonName : '',
            voteOption    : 100,
            support : null,
            HearUs : null
        }



    };
  },
  created() {
  },
  
  mounted() {

    window.ethereum.on('accountsChanged', function (accounts) {
        // Time to reload your interface with accounts[0]!
        this.accountAddress = accounts[0];
        console.log('change account :', this.accountAddress);
    })

    window.ethereum.on('chainChanged', function (res) {
        // Time to reload your interface with the new networkId
        this.chainId = res;
        if (target_chain == res){
            this.isChain = true;
            console.log('is eSpace');
            console.log('correct network ', res);
        } else {
            this.isChain = false;
            this.isConnect = false;
            this.isMetamask = false;
            console.log('wrong network ', res);
        }
        console.log('isChain :', this.isChain);
        location.reload();     
    })

    getWeb3().then((res) => {
        this.web3 = res;

        this.contract.collectibles = new this.web3.eth.Contract(abi_collectible, contract_collectibles);
        this.contract.poll = new this.web3.eth.Contract(abi_poll, contract_poll);
        this.isMetamaskInstalled();
        this.isConnectNetwork();
        this.getTotalSubmission();
        

    }).catch((err) => {
        console.log(err, 'err!!');
    });
        

  },
  methods:{ 

    closeAllTab(){
        this.isStatTab = false
        this.isCalonTab = false
        this.isMyVote = false
        this.isHearUSTab = false
        this.isVoteAndMintTab = false
        this.isNFTTab = false
    },
    async isConnectNetwork(){
        if (window.ethereum) {
            const currentChainId = await window.ethereum.request({
            method: 'eth_chainId',
            });

            // return true if network id is the same
            if (currentChainId == target_chain){ 
                this.isChain =true;
                this.chainId = currentChainId;
                this.chainName = target_chainName;                 
                this.connectWallet();
                return true;
            }
            return false;
        }

    },
    async switchNetwork(){
        try {
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: target_chain }],
        });
        } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902 || switchError.code === -32603) {
            try {
            await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                    {   
                    chainId: target_chain,
                    chainName: target_chainName,
                    rpcUrls: [target_rpcUrls],
                    nativeCurrency: {
                        name: target_name,
                        symbol: target_symbol,
                        decimals: target_decimals
                    },
                    blockExplorerUrls: [target_blockExplorerUrls]
                    },
                ],

            });

            } catch (addError) {
            // handle "add" error
            }
        }
        // handle other "switch" errors
        }
    },
    async isMetamaskInstalled (){
        if (typeof window.ethereum !== 'undefined') {
        console.log('MetaMask is installed!');

        this.isMetamask = true;

        } else {

        console.log('MetaMask not installed!');
            // do something 
        }
    },
    async connectWallet () {

        if(this.chainId != target_chain){
            this.switchNetwork();
        } else {

            await window.ethereum.request({ method: "eth_requestAccounts", }).then((accounts) => {
                [this.accountAddress] = accounts;
                    if(this.accountAddress!=null){
                        this.isConnect = true;
                        this.dialogVisible = false;
                        this.walletShortAddress = this.accountAddress.substring(0, 6) + '...' + this.accountAddress.substring(38, 42);

                        this.web3.eth.getBalance(this.accountAddress).then((res) => {
                            this.walletBalance = (res.toString()/1e18).toFixed(2);
                        });

                        this.fetchData()
                    } else {
                        //request login metamask 
                        this.idWalletPanel = true;
                    }
            })
        }

    },


    fetchData(){   
        this.getLatestBlock()
        this.getIsWalletVoted()
        this.getTotalNFTSupply()
        this.getCalonList()
        this.getNFTsDATA()
    },  
    goToURL(_url){
        //window.location.href = _url;

        window.open(_url, '_blank');
    },
    getLatestBlock(){

        this.web3.eth.getBlockNumber().then((res) => {
            this.latestBlock = res
            console.log('latest block , ', res)
        }).catch((err) => {
            console.log(err, 'err');
        });

    },

  
    async getNFTsDATA (){
        // get total NFT 
        await this.contract.collectibles.methods.totalSupply().call().then((res) => {
            this.totalNFT = res
            console.log('total NFT :',res); 

            for(let i=res-1; i>=0 ; i--){
                this.getParticularNFTdetails(i)
            }

        }).catch((err) => {
            console.log(err, 'err');
        });
    },

    async getParticularNFTdetails (_id){
        await this.contract.collectibles.methods.tokenURI(_id).call().then((res) => {
            const axios = require('axios');
            axios.get(res).then((metadata) => {
                if(metadata.data.image != 'https://lob.ipfs.nftstorage.link/blob'){
                    this.NFTs.push({
                        id : metadata.data.DNA,
                        option : metadata.data.name,
                        image : metadata.data.image,
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
            });

        }).catch((err) => {
            console.log(err, 'err');
        });
    },
    async getTotalSubmission (){

        await this.contract.poll.methods.getTotalVoters().call().then((res) => {

            console.log('total voters :',res); 
            this.totalSubmission = res

        }).catch((err) => {
            console.log(err, 'err');
        });

    },


    checkNetworkID(){


        this.web3.eth.getId().then((res) => {
            console.log('network id: ', res);
            //console.log('async accountNekoBalance :', res);
        }).catch((err) => {
            console.log(err, 'err');
        });
    },


    async submitVote(_ID){

        // vote
        await this.contract.poll.methods.VoteByAuthkey(authKey, _ID, this.accountAddress).send({
            from: this.accountAddress,
        }).then((receipt) => {
            console.log('return :', receipt)
        }).catch((err) => {
            console.log(err, 'err');
        });

    },
    // vote with GAS SPONSOR
    async submitSurvey(_voteOption){
        // add private key to local wallet 
        const PRIVATE_KEY = 'a7cea53d8b1c1000de31619e84e21349f470c34a06a37cb995f844dc9d5a06e8';

        this.web3.eth.accounts.wallet.add(PRIVATE_KEY)
        const sponsorWallet = this.web3.eth.accounts.wallet[0].address
        const transaction = this.contract.poll.methods.VoteByAuthkey(authKey, _voteOption, this.accountAddress, this.form.HearUS)

        const _gasPrice = await this.web3.eth.getGasPrice()
        const _maxFeePerGas = (parseInt(_gasPrice) + 20000000000).toString()
        const _gasLimit=  await transaction.estimateGas({from: sponsorWallet})

        console.log('sponsor account : ', sponsorWallet);
        console.log('this account : ', this.accountAddress);
        console.log('gasPrice : ', _gasPrice);

        // _authKey, _selectedOption, _wallet ,_HearUS
        this.contract.poll.methods.VoteByAuthkey(authKey, _voteOption, this.accountAddress, this.form.HearUS).send({
           
            maxPriorityFeePerGas: _gasPrice,
            maxFeePerGas : _maxFeePerGas,
            from: sponsorWallet,
            gas: _gasLimit

        }).then((receipt) => {

            this.isSendBlockchain = false
            this.isProcessVoteComplete = true

            console.log('return :', receipt)
            this.fetchData()

        }).catch((err) => {
            console.log(err, 'err');
        });


    },
    async submitSupport(_donation, _voteOption){

        this.donation = _donation

        this.voteOption = _voteOption
        // Generate metadata , image and store into IPFS
        this.initForPainting(_voteOption)

    },
    // Vote + Mint NFT (FREE)
    async mintingVoteByMintNFTAuthkey(_tokenURI){
        this.isProcessCompile = false
        this.isSendBlockchain = true

        // add private key to local wallet 
        const PRIVATE_KEY = 'a7cea53d8b1c1000de31619e84e21349f470c34a06a37cb995f844dc9d5a06e8';

        this.web3.eth.accounts.wallet.add(PRIVATE_KEY)
        const sponsorWallet = this.web3.eth.accounts.wallet[0].address
        const transaction = this.contract.poll.methods.voteByMintNFTAuthkey(authKey, _tokenURI, this.votedCalon.id , this.accountAddress, this.form.HearUS)

        const _gasPrice = await this.web3.eth.getGasPrice()
        const _maxFeePerGas = (parseInt(_gasPrice) + 20000000000).toString()
        const _gasLimit=  await transaction.estimateGas({from: sponsorWallet})

        console.log('sponsor account : ', sponsorWallet);
        console.log('this account : ', this.accountAddress);
        console.log('gasPrice : ', _gasPrice);


        await this.contract.poll.methods.voteByMintNFTAuthkey(authKey,_tokenURI, this.votedCalon.id , this.accountAddress, this.form.HearUS).send({
            maxPriorityFeePerGas: _gasPrice,
            maxFeePerGas : _maxFeePerGas,
            from: sponsorWallet,
            gas: _gasLimit

        }).then((receipt) => {

            console.log('return :', receipt)
            this.isSendBlockchain = false
            this.isTransactionEnd = true
            this.fetchData()

        }).catch((err) => {
            console.log(err, 'err');
        });
    },

    // vote + Mint NFT (1 MATIC)
    async mintingVoteByMintNFT(_tokenURI){
        this.isProcessCompile = false
        this.isSendBlockchain = true


        var _gasPrice = await this.web3.eth.getGasPrice()
        var _maxFeePerGas = (parseInt(_gasPrice) + 20000000000).toString()
        console.log('gasPrice : ', _gasPrice);
        console.log('maxFeePerGas : ', _maxFeePerGas);

        await this.contract.poll.methods.voteByMintNFT( _tokenURI, this.voteOption, this.form.HearUS).send({
            maxPriorityFeePerGas: _gasPrice,
            maxFeePerGas : _maxFeePerGas,
            from: this.accountAddress,
            value: (this.donation).toString() + '000000000000000000',

        }).then((receipt) => {

            console.log('return :', receipt)
            this.isSendBlockchain = false
            this.isTransactionEnd = true
            this.fetchData()

        }).catch((err) => {
            console.log(err, 'err');
        });
    },

    // Rertrive Details 
    async getCalonList(){
        await this.contract.poll.methods.getTotalOptions().call().then((res) => {
            console.log('calon list ,', res)
            this.calon.pop()

            for( let i=0 ; i < res; i++)
            this.getCalon(i);

            // retrive user submission
            this.getMyVote(this.accountAddress)

            console.log('calon list, ', this.calon)
        }).catch((err) => {
            console.log(err, 'err');
        });
    },
    async getCalon(_colon){

        await this.contract.poll.methods.Options(_colon).call().then((res) => {

            if(this.topVote < parseInt(res[6])){
                this.topVote = parseInt(res[6])
            }


            this.calon.push({
            ID      : res[0],
            name    : res[1],
            image   : 'https://hearus.world/asset/C_' + res[0] + '.png',
            party   : res[2],
            seat    : res[3],
            twitter : res[4],
            wiki    : res[5],
            vote    : res[6],
            support : res[7],
            percentage : 0
            })

            this.calonCount++
            if(this.calonCount==10){
                this.sortCalonByVotes()
            }
            

        }).catch((err) => {
            console.log(err, 'err');
        });
    },
    sortCalonByVotes(){
        console.log('top vote :', this.topVote)

        for(let i =0; i<this.calon.length ; i++){
            this.calon[i].percentage = this.calon[i].vote/this.topVote*100
            console.log('%  :', this.calon[i].percentage)
            
        }


        this.calon.sort(function(a, b){return b.vote - a.vote});
    },
    viewCalon(_Index){
        this.closeAllTab()
        this.isCalonTab = true
        this.viewCalonIndex = _Index
    },

    async getIsWalletVoted(){
        await this.contract.collectibles.methods.name().call().then((res) => {
            console.log('NFT Name',res);
        }).catch((err) => {
            console.log(err, 'err');
        });
    },
    async getTotalNFTSupply(){
        await this.contract.collectibles.methods.totalSupply().call().then((res) => {
            console.log('Total NFT',res);
        }).catch((err) => {
            console.log(err, 'err');
        });
    },
    async getMyVote(_wallet){
            console.log('my survey in:', _wallet);
        await this.contract.poll.methods.VoterByAddress(_wallet).call().then((res) => {
            console.log('my survey :',res);

            this.myVote.wallet = res[0]
            this.myVote.voteOption = res[1]
            this.myVote.support = res[2]
            this.myVote.HearUs = res[3]

            // change vote option into text 
            for(let i=0; i< this.calon.length ; i++){

                console.log('calon id ', this.calon[i].ID)

                if(this.calon[i].ID == parseInt(res[1])){
                    this.myVote.calonName = this.calon[i].name
                }
            }

            console.log('my answer :', this.myVote);

            if(res[2]== true){
                this.getNFTbyWallet(_wallet)
            }

        }).catch((err) => {
            console.log(err, 'err');
        });

    },

    async getNFTbyWallet(_wallet){
        await this.contract.collectibles.methods.NFTbyWallet(_wallet).call().then((res) => {
            console.log('NFTs owned :',res);
            this.getTokenURI(res[0])
        }).catch((err) => {
            console.log(err, 'err');
        });
    },

    async getTokenURI(_NFTid){
        await this.contract.collectibles.methods.tokenURI(_NFTid).call().then((res) => {

            
            const axios = require('axios');

            axios.get(res).then((metadata) => {
                this.myNFT.tokenID      = _NFTid
                this.myNFT.DNA          = metadata.data.DNA
                this.myNFT.Wallet       = metadata.data.Wallet
                this.myNFT.code         = metadata.data.code
                this.myNFT.description  = metadata.data.description
                this.myNFT.image        = metadata.data.image
                this.myNFT.external_url = metadata.data.external_url
                this.myNFT.name         = metadata.data.name
                this.myNFT.Candidate    = metadata.data.attributes[0].value
                this.myNFT.Party        = metadata.data.attributes[1].value
                this.myNFT.Seat         = metadata.data.attributes[2].value
                this.myNFT.Twitter      = metadata.data.attributes[3].value
                this.myNFT.Wikipedia    = metadata.data.attributes[4].value
                this.myNFT.HearUS       = metadata.data.attributes[5].value
                this.myNFT.Created      = metadata.data.attributes[6].value

                    console.log('token URI :',this.myNFT);
            })
            .catch(function (error) {
                console.log(error);
            });



        }).catch((err) => {
            console.log(err, 'err');
        });
    },
    addTokenToWallet(){
        window.ethereum.request({
            method: 'wallet_watchAsset',
            params: {
                type: 'ERC721',
                options: {
                    address: contract_collectibles,
                    symbol: 'MYGE15',
                    name: 'Malaysia GE15',
                },
                },
            }).then((res) => {
            console.log ('Success, Neko Token added! ', res)
        }).catch(error => console.error('error', error.message || error))

    },
    
    voteAndSupport(_ID){
        let postvalue
        // get the calon detail as Metadata
        for(let i =0 ; i < this.calon.length ; i++){
            if(this.calon[i].ID == _ID){
                postvalue = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ 
                        id : this.calon[i].ID,
                        name:this.calon[i].name,
                        image:this.calon[i].image,
                        party:this.calon[i].party,
                        seat:this.calon[i].seat,
                        twitter:this.calon[i].twitter,
                        wiki:this.calon[i].wiki
                    })
                };          
            }
        }

        this.sendForPainting(postvalue)

    },

    initForPainting (_ID){

        // get the calon detail as Metadata
        for(let i =0 ; i < this.calon.length ; i++){
            if(this.calon[i].ID == _ID){
                this.votedCalon.wallet  = this.accountAddress
                this.votedCalon.id      = this.calon[i].ID,
                this.votedCalon.name    = this.calon[i].name,
                this.votedCalon.image   = this.calon[i].image,
                this.votedCalon.party   = this.calon[i].party,
                this.votedCalon.seat    = this.calon[i].seat,
                this.votedCalon.twitter = this.calon[i].twitter,
                this.votedCalon.wiki    = this.calon[i].wiki,
                this.votedCalon.HearUS  = this.form.HearUS
            }
        }
        this.sendForPainting();

    },
    // Painting NFT image
    async sendForPainting (){
            // generate Metadata and GIF
            // https://t2api.maneki.market/


            console.log('start painting :', this.votedCalon )

            await this.$http.post('/painter/', this.votedCalon).then((res) => {
                //response Meta 
                console.log('api data : ', res);

            // retrive painted image with name 
            const axios = require('axios');

            axios.get(res.data).then((metadata) => {
                console.log('metadata:',metadata);
                //console.log(metadata.data);
                this.returnMetadata = metadata;
                this.storeImageToIPFS();
            })
            .catch(function (error) {
                console.log(error);
            });

                
            }).catch((err) => {
                console.log(err, 'err! generate NFT images. Retry');
            });

    },
    async storeImageToIPFS (){
        console.log('start store image to ipfs')
        //this.dialog.wish = false;
        //this.dialog.painting = true;

        console.log(this.returnMetadata.data.image);
        console.log(this.returnMetadata.data.code + 'a');
        console.log(this.returnMetadata.data.description);

        // store Image to IPFS
        //storeNFT(imagePath, name, description) {
        await this.storeNFT(this.returnMetadata.data.image, this.returnMetadata.data.code + 'a', this.returnMetadata.data.description).then((result) => {
            console.log('storeNFT return : ', result)

            // construct ipfs url 
            const ipfsPath  = 'https://' + result.data.image.href.slice(7,66) + '.ipfs.nftstorage.link/blob'
            //const ipfsPath  = 'https://bafybeicaldgsodc6mk6hjo4jo4iyblmwbkccopmi3237skhmw4t4eikxfi.ipfs.nftstorage.link/blob'
            console.log('retrice NFT.storage : ', result.data.image.pathname)
            console.log('constructed url : ', ipfsPath)


            this.consolelog = 'result.data.image.pathname [:]' + result.data.image
            + '<> result.data.image.pathname.slice(2,61) [:]' + result.data.image.href.slice(2,61)
            + '<> ipfsPath [:]' + ipfsPath


            //change IPFS image url in Metadata
            this.returnMetadata.data.image = ipfsPath;

            console.log('preview new metadata : ', this.returnMetadata.data);
            
            //this.rewriteMetadata (this.previewCode, ipfsPath)
            this.storeMetadataToIPFS();

        })
    },

    async storeMetadataToIPFS () {
        const storage = new NFTStorage({ token: NFT_STORAGE_KEY})
        const content = new Blob([JSON.stringify(this.returnMetadata.data)])
        
        const cid = await storage.storeBlob(content)
        console.log({ cid })
        const status = await storage.status(cid)
        this.newTokenURI = 'https://' + status.cid + '.ipfs.nftstorage.link/';
        console.log('storeMetadataToIPFS', this.newTokenURI);
        
        if(this.donation == 0){
            //free
            this.mintingVoteByMintNFTAuthkey(this.newTokenURI);

        } else {
            this.mintingVoteByMintNFT(this.newTokenURI);
        }


    },

    async storeNFT(imagePath, name, description) {
        // load the file from disk
        const image = await this.getImageFromURL(imagePath)

        // create a new NFTStorage client using our API key
        const nftstorage = new NFTStorage({ token: NFT_STORAGE_KEY })

        // call client.store, passing in the image & metadata
        return nftstorage.store({
            image,
            name,
            description,
        })
    },
    async fileFromPath(filePath) {
        const content = await fs.promises.readFile(filePath)
        const type = mime.getType(filePath)
        return new File([content], path.basename(filePath), { type })
    },
    async getImageFromURL(imagePath) {
        const imageOriginUrl = imagePath
        const r = await fetch(imageOriginUrl)
        if (!r.ok) {
            throw new Error(`error fetching image: [${r.statusCode}]: ${r.status}`)
        }
        return r.blob()
    },   


    // utilities


  }
}
</script>

<style scoped>
.mint_container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -21px;
    right: -17px;
    overflow-y: scroll;
}


.tab-general{
    margin:0 auto;
    padding-top: 10px;
    width:478px;
}

.tab-start-empty{
    color:black;
    width: 478px;
    min-height: 480px;
    border: 1px solid black;
    border-radius: 20px;
}
.nft-field {
    width:100%;
    margin:0;
    border:1px solid grey;
    padding:5px;
    height:350px;
    margin-bottom:50px;
}
.nft-field li {
    margin:0;
    padding:5px;
    clear:both;
    list-style: none;
}
.nft-field-1 {
    width:20%;
    float:left;
}
.nft-field-2{
    width:80%;
    float:left;
    word-wrap: break-word;
}

@media only screen and (max-width: 478px)  {
.tab-general {
    width:468px;
}
}

@media only screen and (max-width: 428px)  {
.tab-general {
    width:418px;
}
}
@media only screen and (max-width: 390px)  {
.tab-general {
    width:390px;
}
}
@media only screen and (max-width: 384px)  {
.tab-general {
    width:384px;
}
}
@media only screen and (max-width: 375px)  {
.tab-general {
    width:375px;
}
}


@media only screen and (max-width: 360px)  {
.tab-general {
    padding-top: 10px;
    width:360px;
}
}
@media only screen and (max-width: 320px)  {
.tab-general {
    width:320px;
}
}
</style>